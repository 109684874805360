import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { currencyFormatter } from "../../util/helper";

import { FULL_MONTH_NAME } from "constants/GlobalConstant";
import ReportViewer from './ReportViewer';
import useMutation from '../../components/customHook/useMutation';
import { useLocation } from 'react-router-dom';

export const ViewTitle = {
  rmView:"RM View",
  checkInView: "Check In",
  checkOutView: "Check Out",
}


function base64ToJson (token) {
  const base64 = token.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
const ReportsRoot = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("payload");

  const { request, loading, data, error } = useMutation({
    method: "POST",
    url: '/bulk_report',
    baseUri: 'report',
  })

  const payload = base64ToJson(param)
  useEffect(() => {
    request(payload)
  }, []);

  if(loading) return null
  if(error) return <div id="error">An error occurred</div>
  if(!data) return null
  return <Reports propertyDetail={data} {...payload}/>
}

const Reports = (props) => {


  // let persistedFilter = getData("filters") === null ? filters : getData("filters");

  const propertyDetail = props?.propertyDetail || {};

  function setMetrics(km, fields) {
    const newKm = { ...km, adr: km?.totalRent / km?.bookedNights };
    const currencyFields = ["adr", "ancillaryRevenue", "totalRevenue", "totalRent"];

    let kmClone = cloneDeep(newKm);
    Object.keys(kmClone).forEach((key) => {
      if (!fields.includes(key)) delete kmClone[key];
      else if (currencyFields.includes(key)) kmClone[key] = currencyFormatter(kmClone[key], propertyDetail.result.currency);
    });

    return kmClone;
  }

  function setPayload() {
    const metricsExport = props.keyMetrics.filter(km => km.checked).map(km => km.value);
    const metricsByMonthExport = props.keyMetricsByMonth.filter(km => km.checked).map(km => km.value);
    return {
      currentMonthCurrentYearADR: currencyFormatter(propertyDetail.currentMonthYearAdr, propertyDetail.result.currency),
      currentMonthCurrentYearOccupancy: propertyDetail.currentMonthCurrentYearOccupancy,
      currentMonthCurrentYearTotalRent: currencyFormatter(propertyDetail.currentMonthCurrentYearTotalRent, propertyDetail.result.currency),
      keyMetrics: {
        currentYear: setMetrics(propertyDetail.keyMetricTableCurrentYear, props.yearlyData ? metricsExport : []),
        last365: setMetrics(propertyDetail.keyMetricTableLast365Days, metricsExport),
        lastYear: setMetrics(propertyDetail.keyMetricTableLastYear, metricsExport),
      },
      keyMetricsByMonth: {
        currentMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonth, props.monthlyData ? metricsByMonthExport : []),
        lastMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonthLastYear, metricsByMonthExport),
      },
      month: FULL_MONTH_NAME[propertyDetail.month - 1],
      property: {
        name: propertyDetail.result?.name,
        city: propertyDetail.result?.city,
        imageUrl: propertyDetail.result?.image,
      },
      year: propertyDetail.year,
    };
  }

  return (
   <ReportViewer setPayload={setPayload} />
  );
};

export default ReportsRoot;
