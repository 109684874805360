import React, {memo, useCallback, useState, useRef, useEffect} from "react";
import styled, { css } from "styled-components";
import { CaretUpOutlined, CaretDownOutlined, CaretDownFilled } from "@ant-design/icons";
import { TableRowBody, TableRowDiv } from "./CommonCSS";
import CheckBox from "../../Checkbox/CheckBox";
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { Popover, Checkbox } from "antd";


const TableRowTitleBody = styled(TableRowBody)`
  text-transform: uppercase;
  background: ${({theme}) => theme.bodyBg};
  z-index: 3;
  position: sticky;
  top: -1px;
`;

const TableRowTitleDiv = styled(TableRowDiv)`
  justify-content: ${({align}) => align};
  display: ${({display}) => display || 'flex'};
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const SortDiv = styled.div`
  width: fit-content;
  height: 24px;
  max-width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
  // border: 1px solid red;
  margin: -2px 0 0 8px;
`;


const Sortup = styled(CaretUpOutlined)`
  width: 10px;
  height: 10px;
  padding: 0px;
  margin: 0px;
  // cursor: pointer;
`;

const SortDown = styled(CaretDownOutlined)`
  width: 10px;
  height: 10px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
`;

const SortButton = css`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px;
  color: ${({theme}) => theme.bodyFg};

  &:hover, &.active {
      color: ${({theme}) => theme.quibbleBlue};
      background: none;
  }
`;

const SelectButton = styled.button`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px;
  cursor: pointer;

  &:hover, &.active {
    background: ${({ theme }) => theme.quibbleHoverGrey };  
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  font-family: Commissioner;
  font-weight: 100;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;
  cursor:pointer;
  background: none;
  transition: all 0.15s linear;
  position: relative;
  white-space: nowrap;
  color: #697273;
`;
const CheckboxContainer = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.quibbleHoverGrey};
  padding: 11.5px 9px;
`;

const StyledTableRowCheckBox =  styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  background: ${({ theme, popoverActive }) => popoverActive ? theme.quibbleBlue : 'none' };  
  border-radius: 4px;
`;

const StyledCaret = styled(CaretDownFilled)`
  background-color: ${({caretActive, theme}) => caretActive && theme.quibbleBlue };
  color: ${({caretActive}) => caretActive && 'white' };
  padding:  ${({caretActive}) => caretActive && '3px 6px'};
  border-radius: 50%;
`;

const CheckboxStyle = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ checked }) => checked && 'transparent'};
    border-color: ${({ checked }) => checked && 'black'};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ checked, theme }) => checked && theme.quibbleBlue};
  }
`;



const TableRowTitle = ({columns=[], data=[], onClickCheckAll,onClickCheckCurrentPage,onClickCheckNone, withCheckBox, checkedRows, length, setSort, sort, activePageLength, caretCheckedValues, setCaretCheckedValues, columnFilter}) => {  

  const allChecked = checkedRows.length === length;
  const lineCondition = checkedRows.length > 0 && checkedRows.length < length;
  const [popoverActive, setPopoverActive] = useState(false);
  const popoverRef = useRef(null);
  const [caretActive, setCaretActive] = useState(false);


  const toggleCaretStatus =() => {
    setCaretActive(prev => !prev)
  }

  const onCheckBoxChange = (value) => {
    setCaretCheckedValues((prevCheckedValues) => {
      if (prevCheckedValues.includes(value)) {
        return prevCheckedValues.filter((item) => item !== value);
      } else {
        return [...prevCheckedValues, value];
      }
    });
  };
  
  const CaretContent = (
    <div>
      {columnFilter &&
      (columns.map((column, index) =>(
        column.label ?
        (<div style={{ display: 'flex',gap: '5px', fontSize: '16px', textTransform:'capitalize' }}>
          <CheckboxStyle key={index} checked={caretCheckedValues.includes(column.label)} onChange={() => onCheckBoxChange(column.label)}></CheckboxStyle>
          <p>{ column.label}</p> 
          </div>)
        : null
      )))}
    </div>
  );

  const onTitleCheckClick = useCallback((value) => {

    if (allChecked) {
      onClickCheckAll('all');
      return;
    };

    if (lineCondition) {
      onClickCheckAll('line');
      return;
    }
  
    onClickCheckAll('empty');

    return;
  }, [onClickCheckAll, allChecked, lineCondition]);

  const titleAlign = {
    left: 'flex-start',
    right: 'flex-end',
  }

  const onSortUpClick = useCallback(({key, index}) => {
    setSort((prev) => {
      if (prev.type === 'ascend' && prev.index === index) {
        return {type: 'normal', key, index}
      };

      return {type: 'ascend', key, index};
    })
  }, [setSort]);

  const onSortDownClick = useCallback(({key, index}) => {
    setSort((prev) => {
      if (prev.type === 'descend' && prev.index === index) {
        return {type: 'normal', key, index}
      };

      return {type: 'descend', key, index};
    })
  }, [setSort]);

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setPopoverActive(false);
    }
  };

  useEffect(() => {
    if(popoverActive) {
      document.addEventListener('mousedown', handleClickOutside)
    } else{
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverActive]);
  
  const content = (
   <div style={{display:'flex', flexDirection:'column', alignItems:'start', width:'200px'}}>
      <SelectButton onClick={() => onClickCheckCurrentPage()} >Current ({activePageLength.length} items)</SelectButton>
      <SelectButton onClick={() => onTitleCheckClick()} >All ({data.length} items)</SelectButton>
      <SelectButton onClick={() => onClickCheckNone()}  >None</SelectButton>
   </div>
  )

  const handlePopoverVisibilityChange = (visible) => {
    setPopoverActive(visible);
  };


  return (
    <TableRowTitleBody>
      {withCheckBox && 
      <CheckboxContainer>
        <StyledTableRowCheckBox popoverActive={popoverActive}>
          <CheckBox checked={allChecked} line={lineCondition} onCheckBoxChange={onTitleCheckClick} />
          <Popover content={content} placement={"bottomLeft"} showArrow={false} trigger={"click"} onOpenChange={handlePopoverVisibilityChange}>
            <CaretDownFilled onClick={() =>{setPopoverActive(prev=>!prev)}}/>
          </Popover>
        </StyledTableRowCheckBox>
      </CheckboxContainer>
      }
      {columns.length ? 
        columns.map((columnData, index) => {
          const sortKey =  columnData['sorterKey'] || columnData['dataIndex'];
          const isVisible = caretCheckedValues.includes(columnData.label);
          return (
            <TableRowTitleDiv key={`${index} ${columnData.label}`} width={columnData.width || 'fit-content'} align={titleAlign[columnData.align] || 'center'} display={ columnFilter && (isVisible ? columnData.display : 'none' )}>
              {columnData.label || ''}
              {columnData.sorter  && sortKey ? 
              <SortDiv>
                <ButtonWithFunction  onClickFunc={onSortUpClick} value={{key: sortKey, index}} ButtonStyle={SortButton} label={<Sortup />} className={sort.type === 'ascend' && sort.index === index ? 'active' : null} />
                <ButtonWithFunction  onClickFunc={onSortDownClick} value={{key: sortKey, index}} ButtonStyle={SortButton} label={<SortDown />} className={sort.type === 'descend' && sort.index === index ? 'active' : null} />
              </SortDiv> : null}
            </TableRowTitleDiv>
            )
        })
      :
      <TableRowDiv width={'fit-content'} align={'center'} >
        Test Table
      </TableRowDiv>
      }
      {columns.length > 0 && columnFilter && (
        <TableRowTitleDiv width={'fit-content'} align={'center'}>
          <Popover content={CaretContent} trigger="click" placement='bottomLeft' showArrow={false} onOpenChange={(visible) => setCaretActive(visible)}>
            <StyledCaret onClick={toggleCaretStatus} caretActive={caretActive}/>
          </Popover>
        </TableRowTitleDiv>
      )}
    </TableRowTitleBody>
  )
}

export default memo(TableRowTitle);