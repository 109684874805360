import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { LineChart, XAxis, YAxis,Tooltip,Line, 
  // ReferenceLine, Scatter 
} from 'recharts';
import { currencyFormatter, showName } from '../../../../../../util/helper';
import CustomTick from './CustomTick';
import { FULL_MONTH_NAME, HALF_DAY_NAME, currentDate } from '../../../../../../constants/GlobalConstant';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { getMultiplier, getOverridePrice } from '../../../../components/utils';
import { BodyCSS } from '../../../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import HighDot from './HighDot';
import LowDot from './LowDot';
import { isEmpty } from 'lodash';
import ImageRating from '../../../../../../components/ReusableComponents/Image/ImageRating';
import { useSelector } from 'react-redux';

const labelStyle = {
  textAnchor: 'middle',
  fontSize: "18px",
  color: "#283334",
  fontWeight: 400
};

const ToolTipDiv = styled.div`
  ${BodyCSS};
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const ValuesBody = styled.div`
  width: 100%;
  padding: 8px 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #D9D9D9;
`;

const ToolTipValues = styled.div`
  width: 100%;
  // height: fit-content;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const TooltipText = styled(BodyText)`
  color: ${({color}) => color};
  font-weight: 400;
`;

const TooltipImageDiv = styled.div`
  width: 100%;
  height: fit-content;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0;
  padding: 10px 16px;
`;

const DescBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ImageThumbNail = styled.div`
  width: 120px;
  height: 60px;
  border-radius: 4px;
  position: relative;
`;

const PricingGraph = ({showOptimize, propertyPricing, monthYear, showComps, showAdr }) => {

  const { propertyDetails } = useSelector(({ property }) => property);

  const xTickFormat = (data) => {
      const dateSplit = data.split('-');
      return Number(dateSplit[2]);
  }

  const yTickFormat = (data) => {
    return currencyFormatter(data);
  }

  const {selectedDates, setSelectedDates, priceAdjust, activeProperty, activeMarket} = useContext(PriceAdjustContext);

  const disableDates = propertyPricing.filter((data) => {
    const dataSplit = data?.date?.split('-');
    return data?.booking?.length || parseInt(dataSplit[1]) !==  monthYear.getMonth() + 1 || new Date(data?.date) < new Date(currentDate);
  }).map((data)=>data?.date);

  const updatePropertyPricing = useMemo(() => {

    const newPropertyPricing = propertyPricing.map((data) => {

      const multiplier = getMultiplier(priceAdjust,activeMarket, activeProperty, 'property', data);

      let optPrice = data.optimizedPrice;

      if (optPrice) {
        const multiplierPercent  = (Math.abs(multiplier) > 0 ? multiplier : 0) / 100;
        const addValue = Number(optPrice * multiplierPercent);
        optPrice  = optPrice + addValue;

        if (data?.maxPrice && optPrice > data?.maxPrice) {
          optPrice = data?.maxPrice;
        };
      
        if (data?.minPrice && optPrice < data?.minPrice) {
          optPrice = data?.minPrice;
        };

      }

      if (!optPrice && data?.maxPrice) {
        optPrice = data?.maxPrice;
      }

      const overridePrice = getOverridePrice(priceAdjust, activeProperty, data);

      

      const overridePriceItem = overridePrice || data.override;

      //changed from ROS-919
      // if (Math.abs(multiplier) > 0) {
      //   const multiplierPercent  = multiplier / 100;
      //   const addValue = Number((optPrice * multiplierPercent).toFixed(0));
      //   optPrice  = optPrice + addValue;
    
      //   if (optPrice > data?.maxPrice) {
      //     optPrice = data?.maxPrice;
      //   };
      
      //   if (optPrice < data?.minPrice) {
      //     optPrice = data?.minPrice;
      //   };
      // }

      return {
        ...data,
        newOptPrice: overridePriceItem ? overridePriceItem : (data?.faroutPrice && (optPrice < data?.faroutPrice)) ? data?.faroutPrice : optPrice,
        highPrice: data?.high ?  data.high.price : null,
        lowPrice: data?.low ?  data.low.price : null,
      };
    });    

    return newPropertyPricing;
   
  }, [activeProperty, priceAdjust, activeMarket, propertyPricing]);

  const renderTooltip = (data) => {
    const {active, label, payload} = data;
    if (!active) return null;
    const dateSplit = label.split('-');

    const date = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2]);
    const dayIndex = date.getDay();
    // const sortedPayload = payload.sort((a,b) => b.value-a.value);

    const optPriceIndex = payload.findIndex((data) => data.dataKey === 'newOptPrice');
    const adrIndex = payload.findIndex((data) => data.dataKey === 'priceLastYearAverage');
    const priceLastYearIndex = payload.findIndex((data) => data.dataKey === 'priceLastYear');

    const compHighIndex = payload.findIndex((data) => data.dataKey === 'highPrice');
    const compLowIndex = payload.findIndex((data) => data.dataKey === 'lowPrice');
    const currentPriceIndex = payload.findIndex((data) => data.dataKey === 'price');

    const dateIndex = updatePropertyPricing.findIndex((data) => data.date === label);
    const propertyHigh = updatePropertyPricing[dateIndex]?.high?.property;
    const propertyLow = updatePropertyPricing[dateIndex]?.low?.property;

    return (
      <ToolTipDiv> 
        <ValuesBody>
          <TooltipText>{`${HALF_DAY_NAME[dayIndex]}, ${date.getMonth() === 0 ? FULL_MONTH_NAME[11] : FULL_MONTH_NAME[date.getMonth()]} ${date.getDate()}`}</TooltipText>
          <ToolTipValues>
              {payload[optPriceIndex]?.value && showOptimize  ? <TooltipText color={'#00BBD2'} > Opt: {currencyFormatter(payload[optPriceIndex]?.value)} </TooltipText> : null}
              {payload[adrIndex]?.value && showAdr  ? <TooltipText color={'#FA7D7D'} > ADR: {currencyFormatter(payload[adrIndex]?.value)} </TooltipText> : null}
              {payload[priceLastYearIndex]?.value  ? <TooltipText color={'#EDB548'} > {Number(dateSplit[0]) - 1}: {currencyFormatter(payload[priceLastYearIndex]?.value)} </TooltipText> : null}
              {payload[compHighIndex]?.value && showComps  ? <TooltipText color={'#B479FF'} > Comp High: {currencyFormatter(payload[compHighIndex]?.value)} </TooltipText> : null}
              {payload[compLowIndex]?.value && showComps  ? <TooltipText color={'#FE8BF2'} > Comp Low: {currencyFormatter(payload[compLowIndex]?.value)} </TooltipText> : null}
              {payload[currentPriceIndex]?.value  ? <TooltipText color={'#00A4E6'} > {dateSplit[0]} : {currencyFormatter(payload[currentPriceIndex]?.value)} </TooltipText> : null}
          </ToolTipValues>
        </ValuesBody>
        {!isEmpty(propertyHigh) && showComps ? 
          <TooltipImageDiv>
            <BodyText margin="0px 0px 8px 0px">{showName(propertyHigh?.name, 32)}</BodyText>
            <DescBody>
              <ImageThumbNail>
                <img src={propertyHigh?.imageUrl} width="100%" height="100%" alt="property thumbnail"/>
                <ImageRating variant='small' value={propertyHigh?.imageScore.toFixed(2)} />
              </ImageThumbNail>
              <TooltipText color={'#B479FF'}>
                {currencyFormatter(updatePropertyPricing[dateIndex]?.high?.price)}
                &nbsp;
                Night
              </TooltipText>
            </DescBody>
          </TooltipImageDiv> 
          : null
        }
        {!isEmpty(propertyDetails) && showComps && (!isEmpty(propertyHigh) || !isEmpty(propertyLow)) ? 
          <TooltipImageDiv>
            <BodyText margin="0px 0px 8px 0px">{showName(propertyDetails?.name, 32)}</BodyText>
            <DescBody>
              <ImageThumbNail>
                <img src={propertyDetails?.tumbnailUrl} width="100%" height="100%" alt="property thumbnail"/>
                {/* <ImageRating variant='small' value={propertyLow?.imageScore.toFixed(2)} /> */}
              </ImageThumbNail>
              <TooltipText color={'#00A4E6'}>
                {currencyFormatter(updatePropertyPricing[dateIndex]?.price)}
                &nbsp;
                Night
              </TooltipText>
            </DescBody>
          </TooltipImageDiv> 
          : null
        }
        {!isEmpty(propertyLow) && showComps ? 
        <TooltipImageDiv>
          <BodyText margin="0px 0px 8px 0px">{showName(propertyLow?.name, 32)}</BodyText>
          <DescBody>
            <ImageThumbNail>
              <img src={propertyLow?.imageUrl} width="100%" height="100%" alt="property thumbnail"/>
              <ImageRating variant='small' value={propertyLow?.imageScore.toFixed(2)} />
            </ImageThumbNail>
            <TooltipText color={'#FE8BF2'}>
              {currencyFormatter(updatePropertyPricing[dateIndex]?.low?.price)}
              &nbsp;
              Night
            </TooltipText>
          </DescBody>
        </TooltipImageDiv> 
        : null
      }
      </ToolTipDiv>
      )
  };

  return (
    <LineChart width={1236} height={536} data={updatePropertyPricing} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <XAxis 
        dataKey="date" 
        stroke="#D9D9D9" 
        tickLine={false} 
        minTickGap={0}
        padding={{right: 12}}
        tick={<CustomTick disableDates={disableDates} selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>}
        tickFormatter={xTickFormat} 
      />
      <XAxis domain={[0, 100]} xAxisId="top" orientation="top" type="number" tick={false} stroke="#D9D9D9"/>
      <YAxis  
        stroke="#D9D9D9" 
        tickLine={false}  
        tick={{stroke: '#283334', fontWeight: 300, strokeWidth: 0.5}}
        padding={{top: 10}}
        label={{ value: "Price", angle: -90, position: 'left', style: labelStyle }} 
        tickFormatter={yTickFormat}
      />
      <YAxis domain={[0, 100]} yAxisId="right" orientation="right" tick={false} type="number" stroke="#D9D9D9"  margin={{left: 10}}/>
      <Tooltip content={renderTooltip} />
      {/* {showAdr && <ReferenceLine y={propertyPricing[0]?.priceLastYearAverage} stroke="#FA7D7D" strokeDasharray="3 3" />}       */}
      <Line type="monotone" hide={!showAdr} dot={false} dataKey="priceLastYearAverage" strokeDasharray="3 3" stroke="#FA7D7D" strokeWidth="2" />
      <Line type="monotone" dot={false} dataKey="price" stroke="#00A4E6" strokeWidth="2" />
      <Line type="monotone" dot={false} dataKey="priceLastYear" stroke="#EDB548" strokeWidth="2" connectNulls={true} />
      <Line type="monotone" hide={!showComps} dot={<HighDot />}  dataKey="highPrice" stroke="#B479FF" strokeWidth="0" connectNulls={true} />
      <Line type="monotone" hide={!showComps} dot={<LowDot />}  dataKey="lowPrice" stroke="#FF94F4" strokeWidth="0" connectNulls={true} />
      <Line type="monotone" hide={!showOptimize} dot={false} dataKey="newOptPrice" stroke="#00BBD2" strokeWidth="2" />
    </LineChart>
  )
}

export default PricingGraph;
